import * as React from "react";
import {httpPostWithSecurityToken} from "../common/rest";
import {isValidDate} from "../common/tools";

class EmptyBottle extends React.Component {

    constructor(props) {
        super(props);
        let contentCopy = [];
        let identity = null;
        if (this.props.content !== null) {
            contentCopy = this.props.content.slice();
            contentCopy.splice(10, 1); //delete the inWineRack column (always false here)
            contentCopy.shift(); //delete the ID (first element)
            identity = this.props.content[0];
        }

        this.state = {
            token: this.props.token,
            id: identity,
            content: contentCopy,  //bottle fields minus the ID
            rowidx: this.props.rowIndex,
            edit: this.props.edit,
        }
        this.save = this.save.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.edit !== this.props.edit) {
            this.setState({
                edit: this.props.edit,
            })
        }

        if (prevProps.rowIndex !== this.props.rowIndex) {
            this.setState({
                rowidx: this.props.rowIndex,
            })
        }
    }

    save(event) {
        event.preventDefault();
        let input = event.target.firstChild;
        let orginalData = this.state.content.slice();
        let bottleData = this.state.content.slice();
        bottleData[this.state.edit.cell] = input.value;

        let priceFormatted = bottleData[7] === null ? null : bottleData[7].replace(",", "."); //needed so that the modelmapper can convert from String to BigDecimal

        const bottleDataToUpdate = {
            id: this.state.id,
            consumedDate: bottleData[0],
            description: bottleData[1],
            winery: bottleData[2],
            country: bottleData[3],
            region: bottleData[4],
            grapeVariety: bottleData[5],
            vintage: bottleData[6],
            price: priceFormatted,
            created: bottleData[8],
            tastingNotes: bottleData[9],
            scoreOnTen: bottleData[10],
        };

        try {
            this.validate(bottleData[0], bottleData[6], priceFormatted, bottleData[8], bottleData[10]);
            httpPostWithSecurityToken(`/api/update-bottle`, bottleDataToUpdate, this.props.token)
                .then(response => {
                    if (response.status !== 200) {
                        console.log('Status code not 200');
                        this.setState({
                            content: orginalData,  //put back previous state
                            edit: false
                        })
                    } else {
                        let updatedBottleWithoutId = Object.values(response.data);
                        updatedBottleWithoutId.splice(10, 1); //delete the inWineRack column (always false here)
                        updatedBottleWithoutId.shift();
                        this.setState({
                            content: updatedBottleWithoutId,
                            edit: false
                        })
                    }
                })
                .catch(error => {
                    console.log('error ' + error);
                    this.setState({
                        content: orginalData,  //put back previous state
                        edit: false
                    })
                });
        } catch (err) {
            console.log('error=' + err);
            //Validation failed
        }
    }

    validate(consumedDate, vintage, price, created, scoreOnTen) {
        let errorMsg = 'Validation failed';

        if (!isValidDate(consumedDate)) {
            console.log('Not a valid date');
            throw errorMsg;
        }

        if (vintage != null) {
            if (isNaN(vintage) || vintage.toString().includes('.') || vintage.toString().includes(',')) {
                console.log('vintage not a number');
                throw errorMsg;
            }
        }

        if (isNaN(price)) {
            console.log('Priceformatted not a number');
            throw errorMsg;
        }

        if (!isValidDate(created)) {
            console.log('Not a valid date');
            throw errorMsg;
        }

        if (scoreOnTen != null) {
            if (isNaN(scoreOnTen) || scoreOnTen.toString().includes('.') || scoreOnTen.toString().includes(',')) {
                console.log('Score is not a integer number');
                throw errorMsg;
            }
        }
    }

    render() {
        return (
            <tr key={this.state.rowidx}>
                {this.state.content.map((cell, idx) => {

                        const edit = this.state.edit;
                        let content = cell;
                        //puts default value as content (thus cell) first, and then replaced content with the new content
                        //being the submit form with input field

                        if (edit && edit.row === this.state.rowidx && edit.cell === idx) {
                            content = <form className="form-bottle" onSubmit={this.save}>
                                <input className="input-bottle" type='text' defaultValue={content} autoFocus/>
                            </form>;
                        }

                        return <td className="td-bottle" key={idx} data-row={this.state.rowidx}>{content}</td>;

                    }
                )}
                <td style={{verticalAlign: "middle", padding: "3px"}}><a href="#/" id={this.state.id}
                                                                         onClick={this.props.unDrinkBottle}
                                                                         className="button-link">Undrink</a></td>
                <td><p className="height-fifteen-pixels">&nbsp;</p></td>
            </tr>
        );
    }

}

export default EmptyBottle;