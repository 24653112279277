import * as React from "react";
import Table from "react-bootstrap/Table";
import {replaceSpaceByBordeauxUnderscore} from "../common/tools";

class NavigateToRackPopUp extends React.Component {

    userClicksNavigateToRack() {
        document.getElementById("navigateToRackPopUpId").style.display = "none";
    }

    render() {
        return (
            <div className="overlay" id="navigateToRackPopUpId">
                <div className="popup">
                    <Table id="wineRackNavId" responsive="sm">
                        {
                            this.props.wineRackData.map((wineRack, wineRackIdx) => {
                                    return (
                                        <tr>
                                            <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                                <a href={"#" + wineRack.data.id + "-navigate"} className="button-link"
                                                   onClick={this.userClicksNavigateToRack}>{replaceSpaceByBordeauxUnderscore(wineRack.data.name)} </a>
                                            </td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </Table>

                </div>
            </div>
        );
    }
}

export default NavigateToRackPopUp;