import axios from 'axios';

export function httpGetWithSecurityToken(url, token) {
    const options = {
        url,
        method: 'GET',
        mode: 'no-cors',
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {},
    };
    return axios(options);
}

export function httpPostNoBodyWithSecurityToken(url, token) {
    const options = {
        url,
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    };
    return axios(options);
}

export function httpPostWithSecurityToken(url, body, token) {
    const options = {
        url,
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: body,
    };
    return axios(options);
}

export function httpPost(url, body) {
    const options = {
        url,
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
        },
        data: body,
    };
    return axios(options);
}

export function formatErrorResponse(error) {
    if (error && error.response) {
        return `HTTP ${error.response.status}\n${JSON.stringify(error.response.data, null, 2)}`;
    }
}