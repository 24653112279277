import * as React from "react";
import Select from "react-select";

class BottleSlotMenuPopUp extends React.Component {

    render() {
        return (
            <div className="overlay" id="bottleSlotMenuId">
                <div className="popup">
                    <form>
                        <p><h5>{this.props.clickedBottleDescription}</h5></p>
                        <button type="button" className="btn btn-primary" style={{backgroundColor: '#630d35', border: "none"}} onClick={this.props.drinkBottlePopup}>Drink</button>&nbsp;
                        <button type="button" className="btn btn-secondary"  style={{ border: "none"}} onClick={this.props.removeBottleFromWineRack}>Remove</button>
                        <button type="button" className="btn btn-cancel" onClick={this.props.userClicksClose}>Close</button>
                        <Select isMulti={false} id="bottleSelection" name="bottleSelection" placeholder="Put bottle in slot" options={this.props.bottleSuggestions}
                                className="basic-multi-select" classNamePrefix="select"
                                onChange={this.props.userClicksInsert} value={this.props.bottleSelectedOption} />
                    </form>
                </div>
            </div>
        );
    }
}

export default BottleSlotMenuPopUp;
