import * as React from "react";
import {httpPostNoBodyWithSecurityToken} from "../common/rest";
import {underlineHeaderText} from "../common/tools";

class Logout extends React.Component {

    constructor(props) {
        super(props);
        this.userClickLogOutFromAllDevices = this.userClickLogOutFromAllDevices.bind(this);
        this.userClickLogOutFromThisDevice = this.userClickLogOutFromThisDevice.bind(this);
    }

    componentDidMount() {
        underlineHeaderText('logOutMenuId');
        document.getElementById("logOutConfirmationPopupId").style.display = "block";
    }

    userClickLogOutFromThisDevice() {
        let token = this.props.token;
        if (token.startsWith("mob-")) {
            console.log('log out from this device Mobile');
            document.getElementById("logOutConfirmationPopupId").style.display = "none";

            httpPostNoBodyWithSecurityToken(`api/logout/mobile`, this.props.token)
                .then(response => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        console.log('Logout failed ' + response.status);
                    }
                })
                .catch(error => {
                    console.log('Logout failed ' + error.message);
                });

        } else {
            console.log('log out from this device');
            //remove cookies
            document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie = "token-wine-cellar-850210= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            window.location.reload();
        }

    }

    userClickLogOutFromAllDevices() {
        console.log('log out from all devices');
        document.getElementById("logOutConfirmationPopupId").style.display = "none";

        httpPostNoBodyWithSecurityToken(`api/logout/all-devices`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    //remove cookies
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                    document.cookie = "token-wine-cellar-850210= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                    window.location.reload();
                } else {
                    console.log('Logout failed ' + response.status);
                }
            })
            .catch(error => {
                console.log('Logout failed ' + error.message);
            });

    }

    render() {
        return (
            <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded">
                <p className="height-fifty-pixels">&nbsp;</p>

                <div className="overlay" id="logOutConfirmationPopupId">
                    <div className="popup">
                        <h5>Log out</h5>
                        <p className="height-three-pixels"/>
                        <p>You will need to log in again on either this device or all devices depending on your
                            choice.</p>
                        <button type="reset" className="btn btn-primary" onClick={this.userClickLogOutFromThisDevice}
                                style={{marginTop: 10, backgroundColor: '#630d35', border: 'none'}}>This device
                        </button>
                        &nbsp;
                        <button type="reset" className="btn btn-secondary" onClick={this.userClickLogOutFromAllDevices}
                                style={{marginTop: 10}}>All devices
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Logout;