import * as React from "react";

class WineListSearchBox extends React.Component {

    render() {
        return (
            <div className="input-search">
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"/>
                    <input type="text" className="form-control" placeholder="Search" onChange={this.props.search}/>
                </div>
            </div>
        );
    }
}

export default WineListSearchBox;