import * as React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";

class AddWineRackPopUp extends React.Component {

    render() {
        return (
            <div className="overlay" id="addWineRackFormId">
                <div className="popup">
                    <Form onSubmit={this.props.addWineRack} class="text-secondary">
                        <Row>
                            <Col>
                                <Form.Control placeholder="Name" id="name"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Description" id="description"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="# bottles horizontal" id="horizontalSize"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="# bottles vertical" id="verticalSize"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <form>
                                    <Select isMulti={false} id="bottleGrid" name="bottleGrid" options={this.props.gridSuggestions}
                                            className="basic-multi-select" placeholder="Bottle grid mode"  classNamePrefix="select"
                                            onChange={this.props.onChangeBottleGrid} value={this.props.gridSelectedOption}/>

                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Amount of shelves" id="nrOfBottleGrids" hidden/>
                            </Col>
                        </Row>
                        <button type="submit" className="btn btn-primary" style={{marginTop: 10, backgroundColor: '#630d35', border: "none"}}>Add wine rack</button>
                        <button type="reset" className="btn btn-cancel" onClick={this.props.userClicksCancelAddWineRack} style={{marginTop: 10}}>Cancel</button>
                    </Form>
                </div>
            </div>
        );
    }
}

export default AddWineRackPopUp;