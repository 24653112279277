import * as React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ScoreAndTastingNotesPopUp extends React.Component {

    render() {
        return (
            <div className="overlay" id="scoreAndTastingNotesPopupId">
                <div className="popup">
                    <p><h5>Drink bottle {this.props.clickedBottleDescription} ?</h5></p>
                    <Form onSubmit={this.props.drinkBottleFromWineRack}>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Score on 10" id="scoreOnTenId"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Tasting notes" id="tastingNotesId"/>
                            </Col>
                        </Row>
                        <div>
                            <button type="submit" className="btn btn-primary" style={{marginTop: 10, backgroundColor: '#630d35', border: "none"}}>Ok</button>
                            <button type="reset" className="btn btn-cancel" onClick={this.props.userClicksCancelDrink}>Cancel</button>
                        </div>
                    </Form>

                </div>
            </div>
        );
    }
}

export default ScoreAndTastingNotesPopUp;