import * as React from "react";
import Table from "react-bootstrap/Table";

class BottleDetailsPopup extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const { bottle } = this.props;
        const { userClicksCancelDetails } = this.props;
        return (
            <div className="overlay" id="detailsBottlePopup">
                <div className="popup">
                    <Table>
                        <thead><h5>{bottle.description} details</h5></thead>
                        <tr>
                            <td><h6>Description:</h6></td>
                            <td>{bottle.description}</td>
                        </tr>
                        <tr>
                            <td><h6>Winery:</h6></td>
                            <td>{bottle.winery}</td>
                        </tr>
                        <tr>
                            <td><h6>Country:</h6></td>
                            <td>{bottle.country}</td>
                        </tr>
                        <tr>
                            <td><h6>Region:</h6></td>
                            <td>{bottle.region}</td>
                        </tr>
                        <tr>
                            <td><h6>Grapes:</h6></td>
                            <td>{bottle.grapeVariety}</td>
                        </tr>
                        <tr>
                            <td><h6>Vintage:</h6></td>
                            <td>{bottle.vintage}</td>
                        </tr>
                        <tr>
                            <td><h6>Price:</h6></td>
                            <td>{bottle.price}</td>
                        </tr>
                        <tr>
                            <td><h6>Added:</h6></td>
                            <td>{bottle.createdDateStr}</td>
                        </tr>
                        <tr>
                            <td><h6>Location:</h6></td>
                            <td>{bottle.location}</td>
                        </tr>
                    </Table>
                    <button type="reset" className="btn btn-cancel" onClick={userClicksCancelDetails}>Cancel
                    </button>
                </div>
            </div>
    );
    }
    }

    export default BottleDetailsPopup;