import * as React from "react";
import {httpPostWithSecurityToken} from "../common/rest";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {underlineHeaderText} from "../common/tools";

class SettingsScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
        }
        this.changePassword = this.changePassword.bind(this);
        this.handleRestCallChangePassword = this.handleRestCallChangePassword.bind(this);
        this.userClicksOkConfirmationChangePassword = this.userClicksOkConfirmationChangePassword.bind(this);
    }

    componentDidMount() {
        underlineHeaderText('settingsMenuId');
    }

    changePassword(event) {
        event.preventDefault();

        let passwordInput = event.target.newPasswordId.value;
        let confirmPasswordInput = event.target.confirmPasswordId.value;

        if (passwordInput !== confirmPasswordInput) {
            document.getElementById("newPasswordId").style.backgroundColor = "#F8D7DA";
            document.getElementById("confirmPasswordId").style.backgroundColor = "#F8D7DA";
            document.getElementById("newPasswordId").value = '';
            document.getElementById("confirmPasswordId").value = '';
            document.getElementById("newPasswordId").placeholder = 'Passwords do not match';
            document.getElementById("confirmPasswordId").placeholder = 'Passwords do not match';
        } else {
            const body = {
                password: confirmPasswordInput
            };

            this.handleRestCallChangePassword(body);
        }
    }

    userClicksOkConfirmationChangePassword() {
        document.getElementById("passwordChangedConfirmationPopupId").style.display = "none";
        //remove cookies
        document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = "token-wine-cellar-850210= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload();
    }

    userClicksChangePassword() {
        document.getElementById("changePasswordPopupId").style.display = "block";
    }

    userClicksCancelChangePassword() {
        document.getElementById("newPasswordId").style.backgroundColor = "#FFFFFF";
        document.getElementById("newPasswordId").value = '';
        document.getElementById("newPasswordId").placeholder = '';

        document.getElementById("confirmPasswordId").style.backgroundColor = "#FFFFFF";
        document.getElementById("confirmPasswordId").value = '';
        document.getElementById("confirmPasswordId").placeholder = '';

        document.getElementById("changePasswordPopupId").style.display = "none";
    }

    handleRestCallChangePassword(body) {
        httpPostWithSecurityToken(`api/settings/change-password`, body, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    document.getElementById("newPasswordId").style.backgroundColor = "#FFFFFF";
                    document.getElementById("newPasswordId").value = '';
                    document.getElementById("newPasswordId").placeholder = '';

                    document.getElementById("confirmPasswordId").style.backgroundColor = "#FFFFFF";
                    document.getElementById("confirmPasswordId").value = '';
                    document.getElementById("confirmPasswordId").placeholder = '';

                    document.getElementById("changePasswordPopupId").style.display = "none";
                    document.getElementById("passwordChangedConfirmationPopupId").style.display = "block";


                } else {
                    console.log("Error " + response);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    render() {
        return (
            <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded">
                <p className="height-fifty-pixels">&nbsp;</p>

                <div className="overlay" id="passwordChangedConfirmationPopupId">
                    <div className="popup">
                        <h5>Password successfully changed.</h5>
                        <p className="height-three-pixels"/>
                        <p>You will need to login again with your new password.</p>
                        <button type="reset" className="btn btn-primary"
                                onClick={this.userClicksOkConfirmationChangePassword}
                                style={{marginTop: 10, backgroundColor: '#630d35', border: 'none'}}>Ok
                        </button>
                    </div>
                </div>

                <div className="overlay" id="changePasswordPopupId">
                    <div className="popup">
                        <Form onSubmit={this.changePassword} class="text-secondary">
                            <h5>Create new password</h5>
                            <Row>
                                <Col>
                                    <Form.Label>Password</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="password" id="newPasswordId"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="height-three-pixels">&nbsp;</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Confirm password</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="password" id="confirmPasswordId"/>
                                </Col>
                            </Row>
                            <button type="submit" className="btn btn-primary"
                                    style={{marginTop: 10, backgroundColor: '#630d35', border: 'none'}}>Save
                            </button>
                            <button type="reset" className="btn btn-cancel"
                                    onClick={this.userClicksCancelChangePassword} style={{marginTop: 10}}>Cancel
                            </button>
                        </Form>
                    </div>
                </div>

                <a href="#" style={{color: "#630d35", fontSize: "medium"}} onClick={this.userClicksChangePassword}><b>Change
                    your
                    password...</b></a>
                <p className="height-fifty-pixels">&nbsp;</p>
                <p style={{color: "#630d35", fontSize: "medium"}}>Want your account deleted or have other questions?
                    Send an e-mail to
                    admin@wineventory.net and we will gladly help you out. Make sure to mention your account name and/or
                    other details so we can verify you are the owner of the account.</p>
            </div>
        );
    }
}

export default SettingsScreen;