import React from 'react';
import './App.css';
import {Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import WinesScreen from "./wines-screen/WinesScreen";
import WineRacksScreen from "./racks-screen/WineRacksScreen";
import HistoryScreen from "./history-screen/HistoryScreen";
import Cookies from "js-cookie";
import {formatErrorResponse, httpGetWithSecurityToken, httpPost} from "./common/rest";
import AdminScreen from "./admin-screen/AdminScreen";
import SettingsScreen from "./settings-screen/SettingsScreen";
import Logout from "./logout-popup/Logout";
import wineventory_logo from "./images/wineventory_white_bg_medium.jpg";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: '',
            displayRegisterForm: '',
            loginSuccess: '',
            registerSuccess: '',
            cookieTokenValid: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleClick = this.handleClick.bind(this);

    }

    handleSubmit(event) {
        event.preventDefault();
        if (event.currentTarget.getAttribute('id') === 'login-id') {
            const target = event.target;
            const body = {
                username: target.username.value,
                password: target.password.value,
            };
            const rememberMe = target.customCheck1.checked;
            this.handleRestCallLogin(body, rememberMe);
        } else if (event.currentTarget.getAttribute('id') === 'register-id') {
            const target = event.target;
            const body = {
                username: target.username.value,
                password: target.password.value,
            };
            this.handleRestCalRegister(body);
        }
    }

    componentDidMount() {
        const windowUrl = window.location.search;

        // Get the token for mobile users
        let token = new URLSearchParams(windowUrl).get("token"); //http://localhost:8080/?token=d1rdefab-d498-44cb-8519-2cacb4cc5ae9

        if (token === null || token === '') {
            console.log('check cookies');
            token = Cookies.get('token-wine-cellar-850210');
        }

        if (token != null) {
            httpGetWithSecurityToken(`/api/check-token`, token)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            token: token,
                            cookieTokenValid: true
                        })
                    } else {
                        this.setState({
                            token: '',
                            cookieTokenValid: false,
                        })
                    }
                }).catch(error => {
                this.setState({
                    token: '',
                    cookieTokenValid: false,
                })
            });

        } else {
            this.setState({
                token: '',
                cookieTokenValid: false,
            })
        }
    }

    handleLogout() {
        Cookies.remove('token-wine-cellar-850210');
        this.setState({
            token: '',
            displayRegisterForm: '',
            loginSuccess: '',
            registerSuccess: '',
            cookieTokenValid: '',
        })
    }

    handleClick(event) {
        if (this.state.displayRegisterForm === true) {
            this.setState({
                displayRegisterForm: false,
            })
        } else {
            this.setState({
                displayRegisterForm: true,
            })
        }

    }

    handleRestCalRegister(body) {
        httpPost(`/registration/register-wine-cellar-user`, body)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        token: response.data,
                        registerSuccess: true
                    })
                } else {
                    this.setState({
                        token: response.data,
                        registerSuccess: false
                    })
                }
            })
            .catch(error => {
                this.setState({
                    token: formatErrorResponse(error),
                    registerSuccess: false
                })
            });
    }


    handleRestCallLogin(body, rememberMe) {
        httpPost(`/registration/get-token`, body)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        token: response.data,
                        loginSuccess: true,
                        cookieTokenValid: true,
                    })

                    if (rememberMe === true) {
                        let inOneYear = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);
                        Cookies.set('token-wine-cellar-850210', this.state.token, {
                            expires: inOneYear
                        });
                    }

                } else {
                    this.setState({
                        token: '',
                        loginSuccess: false,
                    })
                }
            })
            .catch(error => {
                this.setState({
                    token: '',
                    loginSuccess: false
                })
            });

    }

    render() {
        return (
            <div>
                {this.state.loginSuccess === false && !this.state.displayRegisterForm &&
                    <p className="alert alert-danger" role="alert">
                        Password and/or username incorrect
                    </p>
                }

                {(this.state.loginSuccess === false || !this.state.cookieTokenValid) && !this.state.displayRegisterForm &&
                    <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded" style={{margin: "20px"}}>
                        <img className="navbar-brand" src={wineventory_logo}/>
                        <p className="height-fifteen-pixels"/>
                        <form onSubmit={this.handleSubmit} id="login-id">
                            <h2>Sign In</h2>

                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" id="username" name="username"
                                       placeholder="Enter username" required onChange={this.handleChange}/>
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" id="password" name="newPassword"
                                       placeholder="Enter password" required onChange={this.handleChange}/>
                            </div>

                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                                    <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                </div>
                            </div>

                            <button style={{color: "#FFFFFF", backgroundColor: "#630d35"}} type="submit">Submit</button>
                            <p>&nbsp;</p>
                            <p> No account yet? <a href="/#" onClick={this.handleClick} style={{color: "#630d35"}}><b>Register
                                here.</b></a></p>

                        </form>

                        <p><a
                            href="https://www.privacypolicygenerator.info/live.php?token=PPXibfA6dPB8OvRUa9WmEVZGbHN3h7Bv"><small>Privacy
                            policy </small></a></p>
                    </div>}

                {this.state.displayRegisterForm === true &&
                    <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded" style={{margin: "20px"}}>
                        <img className="navbar-brand" src={wineventory_logo}/>
                        <p className="height-fifteen-pixels"/>
                        <h2>User Registration</h2>
                        <p>
                            Please fill in all the required fields to create a new user account.
                        </p>
                        {this.state.registerSuccess === false &&
                            <p className="alert alert-danger" role="alert">
                                Username already exists
                            </p>}
                        {this.state.registerSuccess === true &&
                            <p className="alert alert-success" role="alert">
                                <p>User successfully registered</p>
                                <p><a href="/#" onClick={this.handleClick} style={{color: "#630d35"}}><b>Log in to your
                                    account.</b></a></p>
                            </p>
                        }
                        {!this.state.registerSuccess &&
                            <form onSubmit={this.handleSubmit} id="register-id">
                                <div className="form-group">
                                    <label>Username &nbsp;</label>
                                    <input type="text" className="form-control" placeholder="Username" id="username"
                                           name="username" required/>
                                </div>
                                <div className="form-group">
                                    <label>Password &nbsp;</label>
                                    <input type="password" className="form-control" placeholder="Password" id="password"
                                           name="newPassword" required/>
                                </div>
                                <button type="submit" style={{color: "#FFFFFF", backgroundColor: "#630d35"}}>Submit
                                </button>
                                <p>&nbsp;</p>
                                <p><a href="/#" onClick={this.handleClick} style={{color: "#630d35",}}><b>Log in to your
                                    account.</b></a></p>
                            </form>}

                    </div>}

                {(this.state.loginSuccess === true || this.state.cookieTokenValid === true) &&
                    <div className="div-font">
                        <div className="App">
                            <Header token={this.state.token}/>
                            <main role="main" className="container">
                                <Route exact path="/" component={() => <WinesScreen token={this.state.token}/>}/>
                                <Route exact path="/Wineracks"
                                       component={() => <WineRacksScreen token={this.state.token}/>}/>
                                <Route exact path="/Emptybottles"
                                       component={() => <HistoryScreen token={this.state.token}/>}/>
                                <Route exact path="/Settings"
                                       component={() => <SettingsScreen token={this.state.token}/>}/>
                                <Route exact path="/Logout" component={() => <Logout token={this.state.token}/>}/>
                                <Route exact path="/admin" component={() => <AdminScreen token={this.state.token}/>}/>
                            </main>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default App;
