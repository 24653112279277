import * as React from "react";

class BottleAddedPopup extends React.Component {

    render() {
        return (
            <div className="overlay-fade-in-out" style={{display: "block"}}>
                <div className="fade-in-out" >
                    <div className="popup" >
                        <p><h5>Bottle(s) added </h5></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottleAddedPopup;