import * as React from "react";
import {httpGetWithSecurityToken, httpPostNoBodyWithSecurityToken} from "../common/rest";
import Table from "react-bootstrap/Table";
import EmptyBottle from "./EmptyBottle";
import {determinePagesToDisplay, underlineHeaderText} from "../common/tools";

const bottleIndexColumnNameMap = {
    0: "consumedDateStr",
    1: 'description',
    2: 'winery',
    3: 'country',
    4: 'region',
    5: 'grapeVariety',
    6: 'vintage',
    7: 'price',
    8: 'createdDateStr',
    9: 'tastingNotes',
    10: 'scoreOnTen'
};

class HistoryScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            headers: ["Consumed", "Description", "Winery", "Country", "Region", "Grapes", "Vintage", "Price", "Added/Created", "Notes", "Score"],
            emptyBottlesData: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null,
            pagesToDisplay: [],
            searchActive: false,
            searchNeedle: null,
            sortBy: null,
            sortAscending: false,
            bottleCount: null,
            edit: {
                row: '', //trick to get the rowindex from the dataset
                cell: '',
            },
        }
        this.sortColumn = this.sortColumn.bind(this);
        this.unDrinkBottle = this.unDrinkBottle.bind(this);
        this.showEditor = this.showEditor.bind(this);
        this.updateBottle = this.updateBottle.bind(this);
        this.loadEmptyBottlesRemoveFocus = this.loadEmptyBottlesRemoveFocus.bind(this);
        this.searchQueryChanged = this.searchQueryChanged.bind(this);
        this.loadBottles = this.loadBottles.bind(this);
    }

    componentDidMount() {
        underlineHeaderText('historyMenuId');
        this.loadEmptyBottlesRemoveFocus(0, false, null, '', this.state.sortAscending);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    sortColumn(event) {
        let column = event.target.cellIndex;
        let sortAscending = !this.state.sortAscending; //Clicking sort again changes the order
        let sortBy = bottleIndexColumnNameMap[column];

        this.loadBottles(0, this.state.searchActive, this.state.searchNeedle, sortBy, sortAscending);
    }

    loadEmptyBottlesRemoveFocus(currentPage, searchActive, searchNeedle, sortBy, sortAscending) {
        let el = document.querySelector(':focus');
        if (el) el.blur();
        this.loadBottles(currentPage, searchActive, searchNeedle, sortBy, sortAscending);
    }

    loadBottles(page, searchActive, searchNeedle, sortBy, sortAscending) {
        let url;
        if (searchActive) {
            url = '/api/search-empty-bottles-for-user?searchQuery=' + searchNeedle + '&page=' + page + '&sortBy=' + sortBy + '&sortAscending=' + sortAscending;
        } else {
            url = '/api/get-empty-bottles-for-user?page=' + page + '&sortBy=' + sortBy + '&sortAscending=' + sortAscending;
        }

        httpGetWithSecurityToken(url, this.state.token)
            .then(response => {
                let constructedEmptyBottleData = [];
                let rawEmptyBottleDataCopy = response.data.dataPaged;
                //pour into an array so slice() etc works
                for (let i = 0, l1 = rawEmptyBottleDataCopy.length; i < l1; i++) {
                    let emptyBottle = Object.values(rawEmptyBottleDataCopy[i]);
                    constructedEmptyBottleData.push(emptyBottle);
                }

                let pagesToDisplay = determinePagesToDisplay(response);

                this.setState({
                    emptyBottlesData: constructedEmptyBottleData,
                    bottleCount: response.data.totalItems,
                    totalPages: response.data.totalPages,
                    pagesToDisplay: pagesToDisplay,
                    currentPage: response.data.currentPage,
                    searchActive: searchActive,
                    searchNeedle: searchActive ? searchNeedle : this.state.searchNeedle,
                    sortBy: sortBy,
                    sortAscending: sortAscending,
                })
            }).catch(error => {
            this.setState({
                token: '',
            })
        });
    }

    searchQueryChanged(event) {
        if (event.target.value.length > 2) {
            this.loadBottles(0, true, event.target.value, '', this.state.sortAscending);
        } else if (event.target.value.length === 0) {
            this.loadBottles(0, false, null, '', this.state.sortAscending);
        }
    }

    unDrinkBottle(row) {
        this.handleRestCallUnDrinkBottle(row)
        console.log('unDrinkBottle ' + this.state.currentPage + ' ' + this.state.searchActive + ' ' + this.state.searchNeedle + ' ' + this.state.sortBy + ' ' + this.state.sortAscending);
    }

    handleRestCallUnDrinkBottle(row) {
        httpPostNoBodyWithSecurityToken(`api/undrink-bottle?id=${row.currentTarget.id}`, this.props.token)
            .then(() => {
                this.loadBottles(this.state.currentPage, this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending);
            });
    }

    showEditor(event) {
        this.setState({
            edit: {
                row: parseInt(event.target.dataset.row, 10), //trick to get the rowindex from the dataset
                cell: event.target.cellIndex,
            }
        });
    }

    updateBottle(event) {
        let row = this.state.edit.row;
        let cell = this.state.edit.cell;
        let bottleDataCopy = this.state.emptyBottlesData.slice();

        //trick to get the input field value inside the form
        bottleDataCopy[row][cell + 1] = event.target.querySelector('input').value; //cell + 1 to account for the ID

        this.setState({
            emptyBottlesData: bottleDataCopy,
            edit: null
        });
    }

    render() {
        return (
            <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded">
                <p className="height-fifty-pixels">&nbsp;</p>
                <div className="div-right">
                    <div className="input-search">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"/>
                            <input type="text" className="form-control" placeholder="Search"
                                   onChange={this.searchQueryChanged}/>
                        </div>
                    </div>
                </div>
                <p className="height-fifteen-pixels"/>
                <p className="height-fifteen-pixels"/>
                <div style={{overflowX: "auto"}}>
                    <Table responsive="sm" class="table table-bordered" size="sm">
                        <thead onClick={this.sortColumn}>
                        <tr>
                            {
                                this.state.headers.map((title, idx) => {
                                    if (this.state.sortBy === bottleIndexColumnNameMap[idx]) {
                                        title += this.state.sortAscending ? '\u2191' : '\u2193';
                                    }
                                    return <th key={idx} className="align-text-top">{title} &nbsp;</th>;
                                })
                            }
                        </tr>
                        </thead>
                        <tbody onClick={this.showEditor} onSubmit={this.updateBottle}>
                        {
                            this.state.emptyBottlesData.map((row, rowidx) => {
                                    return (
                                        //We take the bottle ID as key here, key is also needed for the sorting to work
                                        <EmptyBottle key={row[0]} rowIndex={rowidx} content={row} edit={this.state.edit}
                                                     token={this.state.token} unDrinkBottle={this.unDrinkBottle}/>
                                    );
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </div>
                <div>
                    <Table>
                        <ul class="pagination">
                            {
                                this.state.currentPage !== 0 &&
                                <li className="page-item">
                                    <a className="page-link text-color-bordeaux" href="#"
                                       id='Previous'
                                       onClick={event => this.loadEmptyBottlesRemoveFocus(this.state.currentPage - 1,
                                           this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending)}><b>&lt;</b></a>
                                </li>
                            }
                            {
                                this.state.pagesToDisplay.map((page, rowidx) => {
                                        if (page === this.state.currentPage) {
                                            return (
                                                <li className="page-item">
                                                    <a className="page-link bg-color-bordeaux text-color-white"
                                                       href="#">{page + 1}</a>
                                                </li>);
                                        } else if (page === -1) {
                                            return (
                                                <li className="page-item">
                                                    <a className="page-link text-color-bordeaux">...</a>
                                                </li>);
                                        } else {
                                            return (
                                                <li class="page-item">
                                                    <a className="page-link text-color-bordeaux" href="#"
                                                       id={rowidx}
                                                       onClick={event => this.loadEmptyBottlesRemoveFocus(page, this.state.searchActive,
                                                           this.state.searchNeedle, this.state.sortBy, this.state.sortAscending)}>{page + 1}</a>
                                                </li>
                                            );
                                        }
                                    }
                                )
                            }
                            {this.state.currentPage !== this.state.totalPages - 1 &&
                                <li className="page-item">
                                    <a className="page-link text-color-bordeaux" href="#"
                                       id='Next'
                                       onClick={event => this.loadEmptyBottlesRemoveFocus(this.state.currentPage + 1,
                                           this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending)}>&gt;</a>
                                </li>
                            }
                        </ul>
                    </Table>
                </div>
                <div><p>
                    {this.state.searchActive &&
                        <b>Matching search: {this.state.bottleCount} bottles</b>
                    }
                    {!this.state.searchActive &&
                        <b>Total: {this.state.bottleCount} bottles</b>
                    }
                </p></div>
            </div>
        );
    }
}

export default HistoryScreen;