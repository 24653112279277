import * as React from "react";
import {httpGetWithSecurityToken, httpPostWithSecurityToken} from "../common/rest";

class AdminScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            wineCellarUsers : []
        }
        this.changeUserPassword = this.changeUserPassword.bind(this);
    }

    componentDidMount() {
            httpGetWithSecurityToken(`api/admin/get-users`, this.state.token)
                .then(response => {
                    this.setState({
                        wineCellarUsers:  response.data,
                    })

                }).catch(error => {
                this.setState({
                    token: '',
                })
            });
    }

    changeUserPassword(event){
        event.preventDefault();

        let usernameValue = event.target.id;
        let inputId = usernameValue + '_input';
        let newPassword = document.getElementById(inputId).value;

        const body = {
            username : usernameValue,
            password : newPassword
        };

        httpPostWithSecurityToken(`api/admin/change-password`, body, this.props.token)
            .then(response => {
                if(response.status === 200) {
                    document.getElementById(inputId).style.backgroundColor = "#00FF00";
                    document.getElementById(inputId).value = '';

                } else {
                    document.getElementById(inputId).style.backgroundColor = "#F8D7DA";
                }
            })
            .catch(error => {
                document.getElementById(inputId).style.backgroundColor = "#F8D7DA";
            });
    }

    render() {
        return (
            <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded">
                <p className="height-fifty-pixels">&nbsp;</p>
                <table border='1px' >
                    <tr>
                        <th><h5>Username</h5></th><th><h5>Wine_cellar_id</h5></th><th><h5>Stock_id</h5></th><th><h5>User_role</h5></th>
                    </tr>
                    {
                        this.state.wineCellarUsers.map((user, rowidx) =>{
                                return (
                                    <tr>
                                        <td><h6>{user.username}</h6></td>
                                        <td><h6>{user.wineCellar.id}</h6></td>
                                        <td><h6>{user.wineCellar.stock.id}</h6></td>
                                        <td><h6>{user.userRole.role}</h6></td>
                                        <td>
                                            <input id={user.username + '_input'} type="password" placeholder="New password" />
                                            <button id={user.username} onClick={this.changeUserPassword} className="btn btn-primary btn-sm">Change password</button>
                                        </td>
                                    </tr>

                                );
                            }
                        )
                    }
                </table>
            </div>
        );
    }
}

export default AdminScreen;