import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
        Link
    } from "react-router-dom";
import { MenuItem } from '@material-ui/core';
import {httpGetWithSecurityToken} from "./common/rest";
import header_logo from './images/logo_dark_bg_small.jpg';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            isAdmin : false
        };
    }

    componentDidMount() {
        httpGetWithSecurityToken(`api/admin/is-admin`, this.state.token)
            .then(response => {
                this.setState({
                    isAdmin: response.data,
                })
            }).catch(error => {
            this.setState({
                token: '',
            })
        });

    }

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
                    <div className="container">
                        <img className="navbar-brand" src={header_logo}  style={{paddingBottom: "0px"}} />
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>

                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a  href="/" data-toggle="collapse" data-target=".in">
                                <Link id="winelist" to="/" style={{ textDecoration: 'none', color: 'white' }}>
                                    <MenuItem style={{ paddingLeft: 13 }}>
                                        <i class="fa fa-home fa-fw" aria-hidden="true"/>&nbsp; Wines
                                    </MenuItem>
                                </Link>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/" data-toggle="collapse" data-target=".in">
                                <Link id="wineRacMenuId" to="/Wineracks" style={{ textDecoration: 'none', color: 'white' }}>
                                    <MenuItem style={{ paddingLeft: 13 }}>
                                        <i className="fa fa-table" aria-hidden="true"/>&nbsp; Racks
                                    </MenuItem>
                                </Link>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/" data-toggle="collapse" data-target=".in">
                                <Link id="historyMenuId" to="/Emptybottles" style={{ textDecoration: 'none', color: 'white' }}>
                                    <MenuItem style={{ paddingLeft: 13 }}>
                                        <i className="fa fa-archive" aria-hidden="true"/>&nbsp; History
                                    </MenuItem>
                                </Link>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/" data-toggle="collapse" data-target=".in">
                                    <Link id="settingsMenuId" to="/Settings" style={{ textDecoration: 'none', color: 'white' }}>
                                        <MenuItem style={{ paddingLeft: 13 }}>
                                            <i className="fa fa-cog fa-fw" aria-hidden="true"/>&nbsp; Settings
                                        </MenuItem>
                                    </Link>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/" data-toggle="collapse" data-target=".in">
                                    <Link id="logOutMenuId" to="/Logout" style={{ textDecoration: 'none', color: 'white' }}>
                                        <MenuItem style={{ paddingLeft: 13 }}>
                                            <i className="fa fa-sign-out fa-fw" aria-hidden="true"/>&nbsp; Log out
                                        </MenuItem>
                                    </Link>
                                </a>
                            </li>
                            { this.state.isAdmin &&
                                <li className="nav-item">
                                    <a href="/"data-toggle="collapse" data-target=".in">
                                        <Link to="/admin" style={{textDecoration: 'none', color: 'white'}}>
                                            <MenuItem style={{paddingLeft: 13}}>Admin</MenuItem>
                                        </Link>
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                    </div>
                </nav>
            </header>
        );
    }
}
export default Header;