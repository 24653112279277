import * as React from "react";

class DeleteBottlePopupWineList extends React.Component {

    render() {
        return (
            <div className="overlay" id="deleteBottlePopup">
                <div className="popup">
                    <p><h5>Delete bottle {this.props.deleteBottleDescription} ? </h5></p>
                    <div className="text-right">
                        <button className="btn btn-cancel" onClick={this.props.userClicksCancelDelete}>Cancel</button>
                        <button className="btn btn-primary" style={{backgroundColor: '#630d35', border: "none"}} onClick={this.props.deleteBottle}>Ok</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteBottlePopupWineList;