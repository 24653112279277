import * as React from "react";

class EditWineRackPopUp extends React.Component {

    render() {
        return (
            <div className="overlay" id="editWineRackPopup">
                {this.props.wineRackToEdit !== null &&
                    <div className="popup">
                        <p><h5>Edit wine rack {this.props.wineRackToEdit.name} </h5></p>
                        <table>
                            <tr>
                                <td><input id="newNameWineRackInput" placeholder="New wine rack name" size="25"/></td>
                                <td style={{verticalAlign: "middle", padding: "3px"}}>
                                    <a href="#" id="changeWineRackNameButton"
                                       onClick={this.props.userClicksChangeNameWineRack}
                                       className="button-link">Change</a></td>
                            </tr>
                            <tr>
                                <p className="height-three-pixels">&nbsp;</p>
                            </tr>
                            <tr>
                                <td style={{verticalAlign: "middle", padding: "3px"}}>
                                    <a href="#" id="userClicksRemoveAllBottlesWineRack"
                                       onClick={this.props.userClicksRemoveAllBottlesWineRack}
                                       className="button-link">Remove all bottles</a></td>
                            </tr>

                            {this.props.wineRackToEdit.nrOfBottleGrids === null &&
                                <div id="addRowsOrColumsButtons">
                                    <hr/>
                                    <tr>
                                        <p className="height-three-pixels">&nbsp;</p>
                                    </tr>
                                    <tr>
                                        <td style={{verticalAlign: "middle", padding: "3px"}}>
                                            <a href="#" id="addHorizontalRowOnTopButton"
                                               onClick={this.props.userClicksAddHorizontalRowOnTop}
                                               className="button-link">Add horizontal row top</a></td>
                                    </tr>
                                    <tr>
                                        <p className="height-three-pixels">&nbsp;</p>
                                    </tr>
                                    <tr>
                                        <td style={{verticalAlign: "middle", padding: "3px"}}>
                                            <a href="#" id="userClicksAddHorizontalRowAtBottom"
                                               onClick={this.props.userClicksAddHorizontalRowAtBottom}
                                               className="button-link">Add horizontal row bottom</a></td>
                                    </tr>
                                    <tr>
                                        <p className="height-three-pixels">&nbsp;</p>
                                    </tr>
                                    <tr>
                                        <td style={{verticalAlign: "middle", padding: "3px"}}>
                                            <a href="#" id="userClicksAddVerticalColumnAtLeft"
                                               onClick={this.props.userClicksAddVerticalColumnAtLeft}
                                               className="button-link">Add vertical column left</a></td>
                                    </tr>
                                    <tr>
                                        <p className="height-three-pixels">&nbsp;</p>
                                    </tr>
                                    <tr>
                                        <td style={{verticalAlign: "middle", padding: "3px"}}>
                                            <a href="#" id="userClicksAddVerticalColumnAtRight"
                                               onClick={this.props.userClicksAddVerticalColumnAtRight}
                                               className="button-link">Add vertical column right</a></td>
                                    </tr>
                                </div>
                            }
                        </table>
                        <div className="text-right">
                            <button className="btn btn-cancel" onClick={this.props.userClicksCancelEditWineRack}>Cancel
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default EditWineRackPopUp;