
class BottleData {
    constructor(id, description, winery, country, region, grapeVariety, vintage, price, createdDateStr, location) {
        this.id = id;
        this.description = description;
        this.winery = winery;
        this.country = country;
        this.region = region;
        this.grapeVariety = grapeVariety;
        this.vintage = vintage;
        this.price = price;
        this.createdDateStr = createdDateStr;
        this.location = location;
    }
}

export default BottleData;
