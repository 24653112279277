import * as React from "react";

class DeleteWineRackPopUp extends React.Component {

    render() {
        return (
            <div className="overlay" id="deleteWineRackPopup">
                <div className="popup">
                    <p><h5>Delete wine rack {this.props.wineRackToDeleteName} ?</h5></p>
                    <p>This will not delete any bottles</p>
                    <div className="text-right">
                        <button className="btn btn-cancel" onClick={this.props.userClicksCancelDeleteWineRack}>Cancel</button>
                        <button className="btn btn-primary" style={{backgroundColor: '#630d35', border: 'none'}} onClick={this.props.userClicksOkDeleteWineRack}>Ok</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteWineRackPopUp;